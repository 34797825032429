<!--
 * @Author: yuwenwen
 * @Date: 2022-11-10 14:53:31
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-03-16 18:03:23
 * @FilePath: \nc-funeral-mobile\src\views\products\productsList.vue
-->
<template>
  <div class="page-container products-container">
    <!-- 左侧导航栏 -->
    <div class="left-tab">
      <div
        v-for="(item, index) in leftPanelList"
        :key="index"
        :class="index == leftIndex ? 'tab-item tab-item-active' : 'tab-item'"
        @click="tabChange(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
    <!--右侧商品展示  -->
    <div class="right-panel">
      <div v-if="productList.length > 0">
        <div
          class="product-item"
          v-for="(item, index) in productList"
          :key="index"
          @click="toProductDetails(item)"
        >
          <img class="product-img" :src="item.picture" />
          <div class="product-price-name">
            <div class="name">{{ item.chargeName }}<span v-show="item.specs">({{ item.specs }})</span></div>
            <div class="price"><span>¥</span> {{ item.salesPrice }}</div>
            <div class="stock">库存{{ item.surplusStock }}</div>
          </div>
          <van-icon
            class="add-to-shop-icon"
            name="add"
            @click.stop="addToShopCart(item)"
          />
        </div>
      </div>

      <div v-else style="text-align:center;font-size:16px;color:#aaa;height:200px;line-height:200px">抱歉，暂无该类商品~</div>
    </div>

    <!-- 加入购物车 -->

    <div class="shop-cart" @click="toShopCart">
      <van-badge :content="shopcartNum" max="99">
        <img src="../../assets/images/shop-cart.png" />
      </van-badge>
    </div>
  </div>
</template>
<script>
import { getProductCategory, getProductByCateId } from "@/api/product.js";
import { getImagesUrl } from "@/utils/funeral.js";
import { showToast } from "vant";
export default {
  name: "ProductList",
  data() {
    return {
      leftIndex: 1,
      leftPanelList: [],
      productList: [],
    };
  },
  computed: {
    shopcartNum: {
      get() {
        return this.$store.state.product.shopcartNum;
      },
      set(val) {
        return val;
      },
    },
    shopCartList: {
      get() {
        return this.$store.state.product.shopCartList;
      },
    },
  },
  created() {
    this.getProductCategory();
  },
  mounted() {},
  methods: {
    //获取左侧菜单分类
    async getProductCategory() {
      let [err, res] = await this.$awaitWrap(getProductCategory());
      if (err) {
        return;
      }
      console.log(res);
      this.leftPanelList = res.data;
      this.getProductsByCateId(res.data[1].id);
    },

    // 根据分类获取商品
    async getProductsByCateId(id) {
      let [err, res] = await this.$awaitWrap(getProductByCateId(id));
      if (err) {
        return;
      }
      console.log(res);
      this.productList = res.data.records.map((item) => {
        item.cartNum = 1;
        if(item.picture){
          item.imgList = item.picture.split(",");
        }else{
          item.imgList = [];
        }
        
        
        if (item.imgList.length == 1) {
          item.picture = getImagesUrl(item.picture);
        } else if(item.imgList.length > 1) {
          item.picture = getImagesUrl(item.imgList[0]);
        }else{
          item.picture = '';
        }

        return item;
      });
    },

    // 左侧菜单切换
    tabChange(item, index) {
      this.leftIndex = index;
      this.getProductsByCateId(item.id);
    },
    // 购物车
    toShopCart() {
      this.$router.push({ path: "/product/cart" });
    },
    // 加入购物车
    addToShopCart(item) {
      console.log(item);
      let storeList = this.shopCartList;

      if (storeList.length > 0) {
        let bool = storeList.some((rec) => {
          return rec.priceId == item.priceId;
        });
        if (bool) {
          storeList.forEach((product) => {
            if (product.priceId == item.priceId) {
              if (product.cartNum >= item.surplusStock) {
                showToast("已是最大库存");
                return;
              } else {
                product.cartNum = product.cartNum + 1;
                showToast("加入购物车成功，请前去购物车查看");
              }
            }
          });
        } else {
          showToast("加入购物车成功，请前去购物车查看");
          storeList.push(item);
        }
      } else {
        showToast("加入购物车成功，请前去购物车查看");
        storeList.push(item);
      }
      this.$store.dispatch("setShopCartList", storeList);
    },
    toProductDetails(item) {
      this.$router.push({
        path: "/product/details",
        query: { id: item.priceId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/product.less";
</style>